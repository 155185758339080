import React, { FC, memo, useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import styles from 'components/App/App.module.scss';
import { useInitializeApp } from 'hooks/useInitializeApp';
import Login from 'components/Login/Login';
import Header from 'components/Header/Header';
import Sidebar from 'components/Sidebar/Sidebar';
import AnalyticsPage from 'pages/AnalyticsPage';
import { routeGroups } from 'cores/routeGroups';
import { toURLSearchParams } from 'cores/toURLSearchParams';
import { useDispatch } from 'react-redux';
import { AnalyticsActions } from 'store/analytics';

interface Props {}

const App: FC<Props> = memo(() => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isAuthorized } = useInitializeApp();

  useEffect(() => {
    if (!isAuthorized) {
      return;
    }

    if (location.pathname === '/') {
      const { key, params, pathname } = routeGroups[0].routes[0];
      const query = toURLSearchParams({ ...params, key });
      history.replace(`${pathname}?${query.toString()}`);
    }
  }, [location, history, isAuthorized]);

  useEffect(() => {
    if (!isAuthorized) {
      return;
    }

    dispatch(AnalyticsActions.getCompanies());
  }, [isAuthorized]);

  if (!isAuthorized) {
    return <Login />;
  }

  return (
    <div className={styles.app}>
      <Header />
      <Sidebar />
      <div className={styles.content}>
        <Switch>
          <Route exact path="/analytics/:type" component={AnalyticsPage} />
        </Switch>
      </div>
    </div>
  );
});

export default App;

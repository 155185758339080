import React, { FC, memo } from 'react';
import styles from 'components/Toast/ToastItem/ToastItem.module.scss';
import { Toast, ToastStatus, ToastVariant } from 'declaration/Toast';
import classNames from 'classnames';
import { useSpring, animated } from 'react-spring';

interface Props {
  toast: Toast;
  option: { y: number };
}

const height = 50;

const ToastItem: FC<Props> = memo(({ toast: { text, status, variant }, option: { y } }) => {
  const spring = useSpring({
    from: { y: (y - 1) * -height, opacity: 1 },
    to: { y: y * -height, opacity: status === ToastStatus.Exit ? 0 : 1 },
    config: {
      mass: 1,
      tension: 300,
      friction: 30,
    },
  }) as any;

  return (
    <animated.div
      data-testid="toast-item"
      style={{
        opacity: spring.opacity,
        transform: spring.y.interpolate((y: number) => `translateY(${y}px)`),
      }}
      className={classNames(styles.toastItem, {
        [styles.failure]: ToastVariant.Failure === variant,
        [styles.information]: ToastVariant.Information === variant,
        [styles.success]: ToastVariant.Success === variant,
        [styles.warning]: ToastVariant.Warning === variant,
      })}
    >
      <span className={styles.bar} />
      <span className={styles.text}>{text}</span>
    </animated.div>
  );
});

export default ToastItem;

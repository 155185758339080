import axios from 'axios';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import { action, PayloadAction } from 'typesafe-actions';
import { createAsyncSagaAction } from 'cores/createAsyncSagaAction';

export enum AppType {
  LOGIN = '@app/LOGIN',
  LOGOUT = '@app/LOGOUT',
  SET_TOKEN = '@app/SET_TOKEN',
}

export interface AppState {
  token: string | null;
}

export const AppActions = {
  login: createAsyncSagaAction(AppType.LOGIN, (data: { username: string; password: string }) => {
    return axios.post('/login/', { ...data, device_type: 'pc' });
  }),
  logout: () => action(AppType.LOGOUT),
  setToken: (token: string | null) => action(AppType.SET_TOKEN, token),
};

const initialState: AppState = {
  token: null,
};

export default handleActions<AppState, any>(
  {
    [AppType.SET_TOKEN]: (state, action: PayloadAction<string, string | null>) => {
      return produce(state, (draft) => {
        draft.token = action.payload;
      });
    },
    [AppType.LOGOUT]: (state) => {
      return produce(state, (draft) => {
        draft.token = null;
      });
    },
  },
  initialState
);

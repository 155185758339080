import React, { FC, memo } from 'react';
import PageWrapper from 'component-system/PageWrapper/PageWrapper';
import Analytics from 'components/Analytics/Analytics';

interface Props {}

const AnalyticsPage: FC<Props> = memo(() => {
  return (
    <PageWrapper title="">
      <Analytics />
    </PageWrapper>
  );
});

export default AnalyticsPage;

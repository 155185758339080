import { useMemo } from 'react';
import { useTypedSelector } from 'hooks/useTypedSelector';

export function useQuery(): URLSearchParams {
  const search = useTypedSelector(
    ({
      router: {
        location: { search },
      },
    }) => search
  );

  return useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);
}

import React, { FC, memo, useCallback, useEffect, useRef } from 'react';
import styles from 'components/LoginForm/LoginForm.module.scss';
import lottie from 'lottie-web';
import { useFormik } from 'formik';
import { MdAccountCircle, MdLock } from 'react-icons/md';
import Ink from 'react-ink';
import { useDispatch } from 'react-redux';
import { AppActions } from 'store/app';
import { useToast } from 'hooks/useToast';
import { useFocus } from 'hooks/useFocus';
import classNames from 'classnames';
import animationData from 'static/24311-online-school-home-page-animated-illustration.json';

interface Props {}

interface LoginFormValues {
  username: string;
  password: string;
}

const LoginForm: FC<Props> = memo(() => {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const usernameRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const [isFocusUsername, onFocusUsername, onBlurUsername] = useFocus();
  const [isFocusPassword, onFocusPassword, onBlurPassword] = useFocus();

  const toast = useToast();
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (values: LoginFormValues) => {
      if (!values.username) {
        if (usernameRef.current) {
          usernameRef.current.focus();
        }

        toast.failure('아이디를 입력해주세요.');
        return;
      }

      if (!values.password) {
        if (passwordRef.current) {
          passwordRef.current.focus();
        }

        toast.failure('비밀번호를 입력해주세요.');
        return;
      }

      dispatch(AppActions.login({ username: values.username, password: values.password }));
    },
    [toast, dispatch]
  );

  const { values, handleSubmit, handleChange } = useFormik<LoginFormValues>({
    onSubmit,
    initialValues: {
      username: '',
      password: '',
    },
  });

  useEffect(() => {
    if (!headerRef.current) {
      return;
    }

    lottie.loadAnimation({
      container: headerRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
    });
  }, []);

  return (
    <div className={styles.loginForm}>
      <div ref={headerRef} className={styles.header} />
      <form onSubmit={handleSubmit} className={styles.form}>
        <div
          className={classNames(styles.formControl, {
            [styles.isFocus]: isFocusUsername,
          })}
        >
          <span className={styles.icon}>
            <MdAccountCircle />
          </span>
          <input
            ref={usernameRef}
            className={styles.input}
            type="text"
            name="username"
            placeholder="아이디"
            value={values.username}
            onChange={handleChange}
            onFocus={onFocusUsername}
            onBlur={onBlurUsername}
          />
        </div>
        <div
          className={classNames(styles.formControl, {
            [styles.isFocus]: isFocusPassword,
          })}
        >
          <span className={styles.icon}>
            <MdLock />
          </span>
          <input
            ref={passwordRef}
            className={styles.input}
            type="password"
            name="password"
            placeholder="비밀번호"
            value={values.password}
            onChange={handleChange}
            onFocus={onFocusPassword}
            onBlur={onBlurPassword}
          />
        </div>
        <div className={styles.actions}>
          <button type="submit" className={styles.submitButton}>
            로그인
            <Ink />
          </button>
        </div>
      </form>
    </div>
  );
});

export default LoginForm;

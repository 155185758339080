export function toURLSearchParams(params: { [key: string]: any }) {
  const query = new URLSearchParams();

  for (let key in params) {
    if (Array.isArray(params[key])) {
      for (let i = 0; i < params[key].length; i++) {
        query.append(key, params[key][i]);
      }
    } else {
      query.set(key, params[key].toString());
    }
  }

  return query;
}

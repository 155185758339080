import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import styles from './Calendar.module.scss';
import { map, chain, isEqual } from 'lodash';
import { DateObject, DateTime } from 'luxon';

interface Props {
  year: number;
  month: number;
  startDate: DateObject;
  onClick: (dateObject: DateObject) => void;
}

const Calendar: React.FC<Props> = ({ year, month, startDate, onClick }) => {
  const dateTime = DateTime.local(year, month);
  const firstDayOfMonth = dateTime.startOf('month').weekday;

  const blanks = [];
  const daysInMonth = [];

  const isStartDate = (day: number) => startDate.year === year && startDate.month === month && startDate.day === day;

  const onClickDate = (e: MouseEvent, day: number) => {
    e.stopPropagation();
    onClick({ year, month, day });
  };

  for (let i = 0; i < firstDayOfMonth % 7; i++) {
    blanks.push(<td key={`empty-${i}`} className={styles.empty} />);
  }

  for (let day = 1; day < dateTime.daysInMonth + 1; day++) {
    daysInMonth.push(
      <td
        key={day}
        className={classNames(styles.date, {
          [styles.startDateText]: isStartDate(day)
        })}
        onClick={e => onClickDate(e, day)}
      >
        {day}
        {isStartDate(day) && <div className={styles.startDate} />}
      </td>
    );
  }

  const totalSlots = [...blanks, ...daysInMonth];
  return (
    <table>
      <tbody>
        {chain(totalSlots)
          .chunk(7)
          .map((row, index) => <tr key={index}>{map(row, d => d)}</tr>)
          .value()}
      </tbody>
    </table>
  );
};

export default Calendar;

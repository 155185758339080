import React, { FC, memo, useMemo } from 'react';
import styles from 'components/Sidebar/Sidebar.module.scss';
import { DateTime } from 'luxon';
import { AiOutlineCalendar, AiOutlineBarChart } from 'react-icons/ai';
import { map } from 'lodash';
import { routeGroups } from 'cores/routeGroups';
import { Link } from 'react-router-dom';
import { toURLSearchParams } from 'cores/toURLSearchParams';
import classNames from 'classnames';
import { useQuery } from 'hooks/useQuery';

interface Props {}

const Sidebar: FC<Props> = memo(() => {
  const today = useMemo(() => {
    return DateTime.local().toFormat('yyyy-MM-dd');
  }, []);

  const query = useQuery();
  const searchKey = query.get('key');

  return (
    <div className={styles.sidebar}>
      <div className={styles.today}>
        <AiOutlineCalendar />
        <p>{today}</p>
      </div>
      <div className={styles.routeGroups}>
        {map(routeGroups, ({ name, routes }, y) => {
          return (
            <div key={y} className={styles.routeGroup}>
              <h1 className={styles.groupName}>
                <AiOutlineBarChart />
                <span>{name}</span>
              </h1>
              {map(routes, ({ key, name, pathname, params }, x) => {
                const isActivated = key === searchKey;

                return (
                  <Link
                    className={classNames(styles.link, {
                      [styles.isActivated]: isActivated,
                    })}
                    to={{ pathname, search: toURLSearchParams({ ...params, key }).toString() }}
                    key={x}
                  >
                    {name}
                  </Link>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default Sidebar;

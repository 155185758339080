import React, { useEffect, useRef } from 'react';
import Calendar from '../Calendar/Calendar';
import styles from './DatePicker.module.scss';
import { DateObject, DateTime } from 'luxon';
import { map } from 'lodash';

const weekdays = [
  {
    weekday: 7,
    weekdayShort: '일'
  },
  {
    weekday: 1,
    weekdayShort: '월'
  },
  {
    weekday: 2,
    weekdayShort: '화'
  },
  {
    weekday: 3,
    weekdayShort: '수'
  },
  {
    weekday: 4,
    weekdayShort: '목'
  },
  {
    weekday: 5,
    weekdayShort: '금'
  },
  {
    weekday: 6,
    weekdayShort: '토'
  }
];

interface Props {
  startDate: DateObject;
  onChangeStartDate: (dateObject: DateObject) => void;
}

const DatePicker: React.FC<Props> = ({ startDate, onChangeStartDate }) => {
  const calendars = [];
  const firstYear = 2014;
  const firstMonth = 1;
  const currentYear = DateTime.local().year;
  const currentMonth = DateTime.local().month;
  const calendarMaskRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (calendarMaskRef.current) {
      const selectedCalendar = document.getElementById(`${startDate.year}-${startDate.month}`);
      if (selectedCalendar) {
        calendarMaskRef.current.scrollTo(0, selectedCalendar.offsetTop);
      }
    }
  }, []);

  const onClickDate = (dateObject: DateObject) => {
    onChangeStartDate(dateObject);
  };

  for (let month = firstMonth; month <= 12 && firstYear !== currentYear; month++) {
    calendars.push(
      <div key={`${firstYear}-${month}`} id={`${firstYear}-${month}`}>
        <div className={styles.yearWrapper}>
          {firstYear}년 {month}월
        </div>
        <Calendar year={firstYear} month={month} startDate={startDate} onClick={onClickDate}/>
      </div>
    );
  }

  for (let year = firstYear + 1; year < currentYear; year++) {
    for (let month = 1; month <= 12; month++) {
      calendars.push(
        <div key={`${year}-${month}`} id={`${year}-${month}`}>
          <div className={styles.yearWrapper}>
            {year}년 {month}월
          </div>
          <Calendar year={year} month={month} startDate={startDate} onClick={onClickDate}/>
        </div>
      );
    }
  }

  for (let month = 1; month <= currentMonth; month++) {
    calendars.push(
      <div key={`${currentYear}-${month}`} id={`${currentYear}-${month}`}>
        <div className={styles.yearWrapper}>
          {currentYear}년 {month}월
        </div>
        <Calendar year={currentYear} month={month} startDate={startDate} onClick={onClickDate}/>
      </div>
    );
  }

  return (
    <div className={styles.datePicker}>
      <table className={styles.days}>
        <thead>
        <tr>
          {map(weekdays, weekday => (
            <th key={weekday.weekdayShort} className={styles.day}>
              {weekday.weekdayShort}
            </th>
          ))}
        </tr>
        </thead>
      </table>
      <div className={styles.calendarMask} ref={calendarMaskRef}>
        <div className={styles.calendarContent}>{map(calendars, calendar => calendar)}</div>
      </div>
    </div>
  );
};

export default DatePicker;

import { all, put, takeEvery } from 'redux-saga/effects';
import { AppActions, AppType } from 'store/app';
import { createAsyncSaga } from 'cores/createAsyncSaga';
import { Action, PayloadAction } from 'typesafe-actions';
import { Login } from 'declaration/Login';
import { get } from 'lodash';
import { ToastActions } from 'store/toast';
import { ToastVariant } from 'declaration/Toast';
import { localStorageToken } from 'cores/localStorageToken';
import axios, { AxiosResponse } from 'axios';

export default function* rootSaga() {
  yield all([...createAsyncSaga(AppActions), initializeApp(), watchLoginSuccess(), watchLogout()]);
}

function* initializeApp() {
  axios.defaults.baseURL = process.env.REACT_APP_API;

  if (localStorageToken) {
    axios.defaults.headers = {
      authorization: `JWT ${localStorageToken}`,
    };
  }

  if (localStorageToken) {
    yield put(AppActions.setToken(localStorageToken));
  }
}

function* watchLoginSuccess() {
  yield takeEvery(AppActions.login.success, function* (action: PayloadAction<string, AxiosResponse<Login>>) {
    const login = action.payload.data;
    const token = get(login, 'token');
    const user = get(login, 'user');
    const isStaff = get(user, 'is_staff');

    if (isStaff) {
      yield put(AppActions.setToken(token));
      localStorage.setItem('token', token);
      axios.defaults.headers = {
        authorization: `JWT ${token}`,
      };
    } else {
      yield put(ToastActions.createToast({ variant: ToastVariant.Failure, text: '권한이 없습니다.' }));
    }
  });
}

function* watchLogout() {
  yield takeEvery(AppType.LOGOUT, function* (action: Action<string>) {
    localStorage.removeItem('token');
  });
}

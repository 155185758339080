import Hashids from 'hashids';

export const routeGroups = [
  {
    name: '협회',
    routes: [
      {
        key: 'analytics-ovipa',
        name: '통계',
        pathname: '/analytics/ovipa/',
        params: {
          frequency: 'weekly',
          start_date: '2019-07-01',
        },
      },
    ],
  },
];

for (let i = 0; i < routeGroups.length; i++) {
  for (let j = 0; j < routeGroups[i].routes.length; j++) {
    routeGroups[i].routes[j].key = new Hashids(routeGroups[i].routes[j].key).encode(1);
  }
}

import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './AnalyticsGraph.module.scss';
import { Ovipa } from 'declaration/Ovipa';
import { map, get, chain } from 'lodash';
import { DisplayObject, Line, Rectangle, Text } from 'canvas-object';
import FastVector from 'fast-vector';
import { toLocaleString } from 'cores/toLocaleString';
import classNames from 'classnames';
import Ink from 'react-ink';
import { DateTime } from 'luxon';
import { getPosition } from 'cores/getPosition';

interface Props {
  companies: Array<{ name: string; slug: string; color: string }>;
  data: Array<Ovipa>;
}

const scale = 2;
const marginLeft = 60;
const marginRight = 20;
const marginTop = 20;
const marginBottom = 70;

function getRound(diff: number) {
  if (diff > 10000) {
    return 10000;
  } else if (diff >= 5000) {
    return 5000;
  } else if (diff >= 4000) {
    return 4000;
  } else if (diff >= 3000) {
    return 3000;
  } else if (diff >= 2000) {
    return 2000;
  } else if (diff >= 1000) {
    return 1000;
  } else if (diff >= 500) {
    return 500;
  } else {
    return 100;
  }
}

const AnalyticsGraph: FC<Props> = memo(({ companies, data }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const selectedColumnPopupRef = useRef<HTMLDivElement | null>(null);
  const [key, setKey] = useState(Math.random());
  const [mousePosition, setMousePosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [selectedColumnIndex, setSelectedColumnIndex] = useState(-1);
  const [selectedCompanies, setSelectedCompanies] = useState([
    ...chain(companies)
      .map(({ slug }) => slug)
      .value(),
  ]);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    if (!(canvasRef.current.parentNode instanceof HTMLElement)) {
      return;
    }

    const onResize = () => {
      if (!canvasRef.current) {
        return;
      }

      if (!(canvasRef.current.parentNode instanceof HTMLElement)) {
        return;
      }

      const width = canvasRef.current.parentNode.offsetWidth;
      const height = canvasRef.current.parentNode.offsetHeight;

      canvasRef.current.setAttribute('width', (width * scale).toString());
      canvasRef.current.setAttribute('height', (height * scale).toString());

      canvasRef.current.style.width = `${width}px`;
      canvasRef.current.style.height = `${height}px`;

      setKey(Math.random());
    };

    onResize();
    window.addEventListener('resize', onResize);
  }, [setKey]);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    const context = canvasRef.current.getContext('2d');

    if (!context) {
      return;
    }

    const graphicObjects: Array<DisplayObject> = [];

    const value = chain(data)
      .map(({ stats, time }) => ({
        time,
        stats: chain(stats)
          .filter((stat) => selectedCompanies.indexOf(get(stat, 'company')) !== -1)
          .value(),
      }))
      .value();

    const maxTotalCount = Math.round(
      chain(value)
        .map(({ stats }) =>
          chain(stats)
            .map((i) => get(i, 'total_count'))
            .max()
            .value()
        )
        .max()
        .value()
    );

    const round = getRound(maxTotalCount);
    const max = Math.ceil(maxTotalCount / round) * round;
    const vertical = 10;

    const width = canvasRef.current.width / scale;
    const height = canvasRef.current.height / scale;

    const graphWidth = width - marginLeft - marginRight;
    const graphHeight = height - marginTop - marginBottom;

    const oneWidth = graphWidth / value.length;
    const oneHeight = graphHeight / vertical;
    const onePrice = max / vertical;

    for (let i = 0; i <= vertical; i++) {
      let y = i * oneHeight + marginTop;
      const isLast = i >= vertical;

      if (isLast) {
        y -= 1;
      }

      const value = max - onePrice * i;

      graphicObjects.push(
        new Text({
          position: new FastVector(marginLeft - 10, y),
          content: isNaN(value) ? '-' : toLocaleString(value),
          fontSize: 12,
          color: '#272e40',
          textAlign: 'right',
          fontFamily: 'Spoqa Han Sans',
          scale,
        }),
        new Line({
          position: new FastVector(marginLeft, y),
          endPosition: new FastVector(graphWidth + marginLeft, y),
          lineWidth: 0.5,
          color: isLast ? '#272e40' : '#c8d1d9',
          scale,
        })
      );
    }

    for (let i = 0; i < value.length; i++) {
      let x = i * oneWidth + marginLeft;
      const isFirst = i === 0;
      const time = DateTime.fromFormat(value[i].time, 'yyyy-MM-dd HH:mm:ss').toFormat('yyyy-MM-dd');
      const statsLength = value[i].stats.length;
      const barWidth = oneWidth * 0.7;
      const barMarginLeft = (oneWidth - barWidth) * 0.5;
      const barOneWidth = barWidth / statsLength;
      const barRealOneWidth = barOneWidth * 0.9;
      const barRealLeftMargin = barOneWidth - barRealOneWidth;
      let barRealOneLeftMargin = (barRealLeftMargin / (statsLength - 1)) * 0.5;

      if (barRealOneLeftMargin === Infinity) {
        barRealOneLeftMargin = barRealLeftMargin * 0.5;
      }

      const timeText = new Text({
        content: time,
        rotation: -Math.PI * 0.25,
        fontSize: 12,
        color: '#272e40',
        textAlign: 'right',
        textBaseline: 'top',
        fontFamily: 'Spoqa Han Sans',
        position: new FastVector(x + oneWidth * 0.5, graphHeight + marginTop + 5),
        scale,
      });

      const timeTextHalfWidth = timeText.getWidth(context) * 0.5;

      timeText.position.x += timeTextHalfWidth * 0.25;

      graphicObjects.push(
        timeText,
        new Line({
          position: new FastVector(x, marginTop),
          endPosition: new FastVector(x, graphHeight + marginTop),
          lineWidth: 0.5,
          color: isFirst ? '#272e40' : '#c8d1d9',
          scale,
        })
      );

      if (i >= value.length - 1) {
        graphicObjects.push(
          new Line({
            position: new FastVector(x + oneWidth - 1, marginTop),
            endPosition: new FastVector(x + oneWidth - 1, graphHeight + marginTop),
            lineWidth: 0.5,
            color: '#c8d1d9',
            scale,
          })
        );
      }

      for (let j = 0; j < statsLength; j++) {
        const company = get(value[i].stats[j], 'company');
        const totalCount = get(value[i].stats[j], 'total_count');
        const intersectionCount = get(value[i].stats[j], 'intersection_count');
        const color = chain(companies)
          .find((i) => i.slug === company)
          .get('color')
          .value();

        const totalHeight = (totalCount / max) * graphHeight;
        const totalOffsetY = graphHeight - totalHeight;
        const totalBarPosition = new FastVector(
          x + j * barOneWidth + barMarginLeft + barRealOneLeftMargin,
          marginTop + totalOffsetY
        );

        graphicObjects.push(
          new Rectangle({
            position: totalBarPosition,
            width: barRealOneWidth,
            height: totalHeight,
            color,
            scale,
          })
        );

        if (intersectionCount > 0) {
          const intersectionHeight = (intersectionCount / max) * graphHeight;
          const intersectionOffsetY = graphHeight - intersectionHeight;
          const intersectionBarPosition = new FastVector(totalBarPosition.x, marginTop + intersectionOffsetY);

          graphicObjects.push(
            new Rectangle({
              position: intersectionBarPosition,
              width: barRealOneWidth,
              height: intersectionHeight,
              color: '#05B0FF',
              scale,
            })
          );
        }
      }
    }

    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    for (let i = 0; i < graphicObjects.length; i++) {
      graphicObjects[i].render(context);
    }
  }, [key, data, selectedCompanies]);

  const handleOnMouseMove = useCallback(
    (e: React.MouseEvent<HTMLCanvasElement>) => {
      if (!canvasRef.current) {
        return;
      }

      const canvasPosition = getPosition(e.currentTarget);

      if (!canvasPosition) {
        return;
      }

      const mousePosition = new FastVector(e.clientX, e.clientY);
      const position = mousePosition.sub(canvasPosition).sub(new FastVector(marginLeft, 0));

      if (position.x < 0) {
        setSelectedColumnIndex(-1);
        return;
      }

      const width = canvasRef.current.width / scale;
      const graphWidth = width - marginLeft - marginRight;
      const oneWidth = graphWidth / data.length;

      const x = Math.max(Math.min(position.x, graphWidth), 0);
      const selectedColumnIndex = Math.floor(x / oneWidth);

      if (selectedColumnIndex >= data.length) {
        setSelectedColumnIndex(-1);
        return;
      }

      setMousePosition(mousePosition.sub(new FastVector(canvasPosition.x, 100)).toObject());
      setSelectedColumnIndex(selectedColumnIndex);
    },
    [data]
  );

  const selectedColumn = useMemo(() => {
    return get(data, selectedColumnIndex);
  }, [selectedColumnIndex]);

  const selectedColumnPopupHalfWidth = selectedColumnPopupRef.current ? selectedColumnPopupRef.current.offsetWidth * 0.5 : 0;
  const selectedColumnPopupHeight = selectedColumnPopupRef.current ? selectedColumnPopupRef.current.offsetHeight : 0;

  return (
    <div className={styles.analyticsGraph}>
      <div className={styles.canvas}>
        <canvas
          ref={canvasRef}
          onMouseMove={handleOnMouseMove}
          onMouseLeave={() => {
            setSelectedColumnIndex(-1);
          }}
        />
        {selectedColumn && (
          <div
            ref={selectedColumnPopupRef}
            className={styles.selectedColumn}
            style={{
              transform: `translate(${mousePosition.x - selectedColumnPopupHalfWidth}px, ${
                mousePosition.y - selectedColumnPopupHeight - 30 - 60
              }px)`,
            }}
          >
            <div className={styles.time}>
              {DateTime.fromFormat(selectedColumn.time, 'yyyy-MM-dd HH:mm:ss').toFormat('yyyy-MM-dd')}
            </div>
            {map(companies, (company, key) => {
              const stat = chain(selectedColumn.stats).find((stat) => stat.company === company.slug).value();

              if (selectedCompanies.indexOf(company.slug) === -1) {
                return null;
              }

              const totalCount = get(stat, 'total_count');
              const intersectionCount = get(stat, 'intersection_count');
              const color = company.color;
              const name = company.name;

              return (
                <div key={key} className={styles.company}>
                  <div className={styles.header}>
                    <span className={styles.shape} style={{ background: color }} />
                    <span className={styles.name}>{name}</span>
                  </div>
                  <div className={styles.body}>
                    <span className={styles.total}>{toLocaleString(totalCount)}</span>
                    {company.slug !== 'prnd' && <span className={styles.intersection}>{toLocaleString(intersectionCount)}</span>}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className={styles.companies}>
        {chain(companies)
          .map(({ name, slug, color }, key) => {
            const isSelected = selectedCompanies.indexOf(slug) !== -1;

            return (
              <div
                key={key}
                onClick={() => {
                  if (isSelected) {
                    setSelectedCompanies((prevState) => {
                      const nextState = [...prevState];
                      const index = nextState.indexOf(slug);

                      if (index !== -1) {
                        nextState.splice(index, 1);
                      }

                      return nextState;
                    });
                  } else {
                    setSelectedCompanies((prevState) => {
                      return [...prevState, slug];
                    });
                  }
                }}
                className={classNames(styles.company, {
                  [styles.isSelected]: isSelected,
                })}
              >
                <span className={styles.shape} style={{ background: color }} />
                <span className={styles.name}>{name}</span>
                <Ink />
              </div>
            );
          })
          .value()}
      </div>
    </div>
  );
});

export default AnalyticsGraph;

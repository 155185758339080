import React, { FC, memo, useCallback } from 'react';
import styles from './Header.module.scss';
import { useDispatch } from 'react-redux';
import { AppActions } from 'store/app';
import Ink from 'react-ink';

interface Props {}

const Header: FC<Props> = memo(() => {
  const dispatch = useDispatch();
  const handleLogout = useCallback(() => {
    dispatch(AppActions.logout());
  }, [dispatch]);

  return (
    <div className={styles.header}>
      <div className={styles.logo} />
      <button className={styles.logout} onClick={handleLogout}>
        로그아웃
        <Ink />
      </button>
    </div>
  );
});

export default Header;

import React, { FC } from 'react';
import styles from './Loading.module.scss';
import { range, get, map } from 'lodash';
import classNames from 'classnames';

interface Props {}

const Loading: FC<Props> = () => {
  return (
    <div className={styles.loading}>
      {map(range(3), i => {
        return <div key={i} className={classNames(styles.item, get(styles, `index${i}`), i % 2 === 0 ? styles.odd : styles.even)} />;
      })}
    </div>
  );
};

export default Loading;

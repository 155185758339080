import React, { FC, memo, useCallback, useMemo } from 'react';
import styles from './SpreadSheet.module.scss';
import { Ovipa } from 'declaration/Ovipa';
import { get, map, reverse, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { MdFileDownload } from 'react-icons/md';
import { toLocaleString } from 'cores/toLocaleString';
import classNames from 'classnames';
import Ink from 'react-ink';
import { useQuery } from 'hooks/useQuery';
import hexToRgba from 'hex-to-rgba';

interface Props {
  companies: Array<{ name: string; slug: string; color: string }>;
  data: Array<Ovipa>;
}

const SpreadSheet: FC<Props> = memo(({ companies, data }) => {
  const query = useQuery();
  const frequency = query.get('frequency');

  const width = useMemo(() => {
    return 100 / (companies.length + 1);
  }, []);

  const handleSpreadSheetDownload = useCallback(() => {
    window.open(process.env.REACT_APP_API + `/admin/analytics/ovipa/excel/?frequency=${frequency}`);
  }, [data, frequency]);

  return (
    <div className={styles.spreadSheet}>
      <div className={styles.header}>
        <button className={styles.download} onClick={handleSpreadSheetDownload}>
          <MdFileDownload />
          엑셀로 내려받기
          <Ink />
        </button>
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>날짜</th>
            {map(companies, ({ name, slug }, key) => {
              return (
                <th className={styles[name]} style={{ width: `${width}%` }} colSpan={slug === 'prnd' ? 1 : 2} key={key}>
                  {name}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {map(reverse(sortBy(data, 'time')), (i, key) => {
            const date = DateTime.fromFormat(i.time, 'yyyy-MM-dd HH:mm:ss').toFormat('yyyy-MM-dd');

            return (
              <tr key={key}>
                <td
                  className={classNames(styles.date, {
                    [styles.isOdd]: (key + 1) % 2 !== 0,
                  })}
                >
                  {date}
                </td>
                {map(companies, (company, key) => {
                  const stat = i.stats.find((stat) => stat.company === company.slug);
                  const color = hexToRgba(company.color, 0.3);
                  const totalCount = get(stat, 'total_count');
                  const intersectionCount = get(stat, 'intersection_count');

                  return (
                    <React.Fragment key={key}>
                      <td style={{ background: color }}>{toLocaleString(totalCount)}</td>
                      {company.slug !== 'prnd' && <td style={{ background: color }}>{toLocaleString(intersectionCount)}</td>}
                    </React.Fragment>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
});

export default SpreadSheet;

export enum ToastVariant {
  Success,
  Warning,
  Failure,
  Information
}

export enum ToastStatus {
  Appear,
  Exit,
}

export interface Toast {
  id: number;
  text: string;
  status: ToastStatus;
  variant: ToastVariant;
}

import { all, put, delay, takeEvery } from 'redux-saga/effects';
import { ToastActions, ToastType } from 'store/toast';
import { Action, PayloadAction } from 'typesafe-actions';
import { Toast, ToastVariant } from 'declaration/Toast';
import { get, isError } from 'lodash';
import { AxiosResponse } from 'axios';

export default function* rootSaga() {
  yield all([watchCreateToasts(), watchToastMessage()]);
}

function* watchCreateToasts() {
  yield takeEvery(ToastType.CREATE_TOAST, exitToasts);
}

function* watchToastMessage() {
  yield takeEvery(
    (action: Action) => {
      return /FAILURE/.test(action.type);
    },
    function* (action: PayloadAction<any, any>) {
      if (isError(action.payload)) {
        const response: AxiosResponse<{ toast_message: string | null }> = get(action.payload, 'response');
        const toastMessage = get(response, ['data', 'toast', 'message']);

        if (toastMessage) {
          yield put(ToastActions.createToast({ variant: ToastVariant.Failure, text: toastMessage }));
        }
      }
    }
  );
}

function* exitToasts({ payload: { id } }: PayloadAction<string, Toast>) {
  yield delay(2000);
  yield put(ToastActions.exitToast(id));
  yield delay(2000);
  yield put(ToastActions.deleteToast(id));
}

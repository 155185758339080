import { connectRouter, RouterState } from 'connected-react-router';
import { combineReducers } from 'redux';
import { createBrowserHistory } from 'history';
import app, { AppState } from 'store/app';
import toast, { ToastState } from 'store/toast';
import loading, { LoadingState } from 'store/loading';
import analytics, { AnalyticsState } from 'store/analytics';

export const history = createBrowserHistory();

export default combineReducers<Application>({
  app,
  toast,
  router: connectRouter(history),
  loading,
  analytics,
});

export interface Application {
  app: AppState;
  toast: ToastState;
  router: RouterState;
  loading: LoadingState;
  analytics: AnalyticsState;
}

import { all, put, takeEvery } from 'redux-saga/effects';
import { Action } from 'redux';
import { get, includes } from 'lodash';
import { LoadingActions } from 'store/loading';
import { AppActions } from 'store/app';

function getPureType(type: string) {
  return type.replace('/INITIAL', '').replace('/SUCCESS', '').replace('/FAILURE', '').replace('/CANCEL', '');
}

const loadingIgnoreActions: string[] = [];

function* watchPendingAsyncActions() {
  yield takeEvery('*', function* (action: Action) {
    if (/\/INITIAL$/g.test(action.type)) {
      const type = getPureType(action.type);
      if (!includes(loadingIgnoreActions, type)) {
        yield put(LoadingActions.setAsync(type, true));
      }
    }
  });
}

function* watchEndAsyncActions() {
  yield takeEvery(
    (action: Action) => {
      return /\/SUCCESS$/g.test(action.type) || /\/FAILURE$/g.test(action.type) || /\/CANCEL$/g.test(action.type);
    },
    function* (action) {
      if (get(action, 'payload.response.status') === 401) {
        yield put(AppActions.logout());
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
      }

      const type = getPureType(action.type);

      if (!includes(loadingIgnoreActions, type)) {
        yield put(LoadingActions.setAsync(type, false));
      }
    }
  );
}

export default function* rootSaga() {
  yield all([watchPendingAsyncActions(), watchEndAsyncActions()]);
}

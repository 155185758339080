import React, { FC, memo } from 'react';
import { Helmet } from 'react-helmet';
import styles from './PageWrapper.module.scss';

interface Props {
  title?: string;
}

const PageWrapper: FC<Props> = memo(({ title, children }) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className={styles.children}>{children}</div>
    </React.Fragment>
  );
});

export default PageWrapper;

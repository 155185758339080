import { useTypedSelector } from 'hooks/useTypedSelector';

export function useInitializeApp() {
  const { token } = useTypedSelector(({ app: { token } }) => ({
    token,
  }));

  return {
    isAuthorized: token !== null,
  };
}

import React, { useEffect, useRef, useState } from 'react';
import qs from 'qs';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './DateFilter.module.scss';
import DatePicker from '../DatePicker/DatePicker';
import { DateObject, DateTime } from 'luxon';
import { RouteComponentProps, withRouter } from 'react-router';
import getQuery from '../../cores/getQuery';

interface Props extends RouteComponentProps {

}

const DateFilter: React.FC<Props> = ({ location: { search, key }, history }) => {
  const ref = useRef<HTMLInputElement>(null);
  const query = getQuery(search);
  const [visibleDatePicker, setVisibleDatePicker] = useState(false);
  const [isWriteMode, setWriteMode] = useState(false);
  // @ts-ignore
  const [startDate, setStartDate] = useState(DateTime.fromISO(query.start_date).toObject());
  const [writeStartDate, setWriteStartDate] = useState('');

  const onClickDateButton = () => {
    setVisibleDatePicker(true);
  };

  useEffect(() => {
    // @ts-ignore
    setStartDate(DateTime.fromISO(query.start_date).toObject());
  }, [key]);

  const onChangeStartDate = (startDate: DateObject) => {
    setStartDate(startDate);
    const updatedQuery = Object.assign(query, { start_date: DateTime.fromObject(startDate).toISODate() });
    history.push(`?${qs.stringify(updatedQuery, { indices: false })}`);
  };

  useEffect(() => {
    if (!isWriteMode && !visibleDatePicker) {
      try {
        const dateTime = DateTime.fromFormat(writeStartDate, 'yyyy-MM-dd');

        if (!dateTime.isValid) {
          return;
        }

        const dateTimeObject = dateTime.toObject();

        onChangeStartDate(dateTimeObject);
        setStartDate(dateTimeObject);
      } catch (e) {
        console.error(e);
      } finally {
        setWriteStartDate('');
      }
    }
  }, [isWriteMode, visibleDatePicker]);

  useEffect(() => {
    if (isWriteMode) {
      if (ref.current) {
        ref.current.focus();
      }
    }
  }, [isWriteMode]);

  const renderDate = () => {
    return (
      <div className={styles.dateWrapper}>
        <label>시작 날짜</label>
        <div className={styles.date}>
          {!isWriteMode && <span className={styles.dateText}>{`${startDate.year}/${startDate.month}/${startDate.day}`}</span>}
          {isWriteMode && (
            <input
              ref={ref}
              type="text"
              value={writeStartDate}
              className={styles.writeInput}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                let value = e.currentTarget.value;

                if (/^\d{5}$/.test(value)) {
                  value = value.replace(/^(\d{4})(\d{1})/, '$1-$2');
                } else if (/^\d{4}-\d{3}$/.test(value)) {
                  value = value.replace(/^(\d{4})-(\d{2})(\d{1})/, '$1-$2-$3');
                }

                setWriteStartDate(value);
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.which === 13 && ref.current) {
                  setVisibleDatePicker(false);
                  setWriteMode(false);
                }
              }}
            />
          )}
          <svg viewBox="0 0 16 16" width={14} height={14}>
            <path
              fill="#425363"
              fillRule="evenodd"
              d="M13.222 1.6h.034V0h-2.333v1.6H4.7V0H2.333v1.6h-.777C.692 1.6.008 2.32.008 3.2L0 14.4c0 .88.692 1.6 1.556 1.6h12.478c.855 0 1.555-.72 1.555-1.6V3.2c0-.88-.7-1.6-1.555-1.6h-.812zM1.367 14.4V4.6h12.89v9.8H1.366zM4 7h1.889v2H4V7z"
            />
          </svg>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.dateFilter}>
      <button
        onClick={onClickDateButton}
        onDoubleClick={() => {
          setWriteMode(true);
        }}
      >
        {renderDate()}
      </button>
      <div className={styles.datePickerWrapper}>
        {visibleDatePicker && (
          <OutsideClickHandler
            onOutsideClick={() => {
              setVisibleDatePicker(false);
              setWriteMode(false);
            }}
          >
            <DatePicker startDate={startDate} onChangeStartDate={onChangeStartDate} />
          </OutsideClickHandler>
        )}
      </div>
    </div>
  );
};

export default withRouter(DateFilter);

import React, { FC, memo, useEffect } from 'react';
import styles from './Analytics.module.scss';
import { useQuery } from 'hooks/useQuery';
import { useHistory, useParams } from 'react-router';
import { get, map } from 'lodash';
import { useDispatch } from 'react-redux';
import { AnalyticsActions, AnalyticsType } from 'store/analytics';
import { useTypedSelector } from 'hooks/useTypedSelector';
import AnalyticsGraph from 'components/AnalyticsGraph/AnalyticsGraph';
import SpreadSheet from 'components/SpreadSheet/SpreadSheet';
import classNames from 'classnames';
import { GoCheck } from 'react-icons/go';
import ColorHash from 'color-hash';
import Loading from 'components/Loading/Loading';
import DateFilter from '../../component-system/DateFilter/DateFilter';

const colorHash = new ColorHash({ lightness: 0.6, saturation: 0.35 });

interface Props {}

const frequencies = [
  {
    name: 'Daily',
    value: 'daily',
  },
  {
    name: 'Weekly',
    value: 'weekly',
  },
  {
    name: 'Monthly',
    value: 'monthly',
  },
];

export const colors = ['#05B0FF', '#EC6878', '#6DA2E9', '#F58F5B'];

const Analytics: FC<Props> = memo(() => {
  const query = useQuery();
  const history = useHistory();
  const params = useParams();
  const type = get(params, 'type');
  const frequency = query.get('frequency');
  const startDate = query.get('start_date');
  const endDate = query.get('end_date');
  const selectedFrequency = query.get('frequency');
  const dispatch = useDispatch();
  const { companies, analytics, isLoading } = useTypedSelector(
    ({ analytics: { companies, analytics }, loading: { asyncMap } }) => ({
      companies: map(companies, ({ name, slug }, key) => {
        const sampleColor = get(colors, key);
        const hash = (name + slug).split('').map(i => i.charCodeAt(0)).join('');

        return {
          name,
          slug,
          color: sampleColor ? sampleColor : colorHash.hex(hash),
        };
      }),
      analytics,
      isLoading: get(asyncMap, AnalyticsType.GET_ANALYTICS) > 0,
    })
  );

  useEffect(() => {
    dispatch(AnalyticsActions.truncateAnalytics());
    dispatch(AnalyticsActions.getAnalytics(type, { frequency, start_date: startDate, end_date: endDate }));
  }, [type, frequency, startDate, endDate, dispatch]);

  return (
    <div className={styles.analytics}>
      <DateFilter />
      <div className={styles.frequencies}>
        {map(frequencies, ({ name, value }, key) => {
          const isSelected = value === selectedFrequency;

          return (
            <div
              key={key}
              className={classNames(styles.frequency, isSelected && styles.isSelected)}
              onClick={() => {
                query.set('frequency', value);
                history.push(`?${query.toString()}`);
              }}
            >
              <span className={styles.shape}>
                <GoCheck />
              </span>
              <span className={styles.name}>{name}</span>
            </div>
          );
        })}
      </div>
      {frequency !== 'daily' && (
        <div className={styles.graph}>
          {analytics.length > 0 && <AnalyticsGraph companies={companies} data={analytics} />}
          {isLoading && <Loading />}
        </div>
      )}
      <div className={styles.spreadSheet}>
        {analytics.length > 0 && <SpreadSheet companies={companies} data={analytics} />}
        {isLoading && <Loading />}
      </div>
    </div>
  );
});

export default Analytics;

import produce from 'immer';
import { handleActions } from 'redux-actions';
import { action, PayloadAction } from 'typesafe-actions';
import { Toast, ToastStatus } from 'declaration/Toast';
import { findIndex } from 'lodash';

export enum ToastType {
  CREATE_TOAST = '@toast/CREATE_TOAST',
  DELETE_TOAST = '@toast/DELETE_TOAST',
  EXIT_TOAST = '@toast/EXIT_TOAST'
}

export interface ToastState {
  toasts: Toast[]
}

export const ToastActions = {
  createToast: (toast: Omit<Toast, 'id' | 'status'>) => action(ToastType.CREATE_TOAST, { ...toast, id: Math.random(), status: ToastStatus.Appear }),
  deleteToast: (toastId: Toast['id']) => action(ToastType.DELETE_TOAST, toastId),
  exitToast: (toastId: Toast['id']) => action(ToastType.EXIT_TOAST, toastId),
};

const initialState: ToastState = {
  toasts: []
};

export default handleActions<ToastState, any>(
  {
    [ToastType.CREATE_TOAST]: (state, action: PayloadAction<string, Toast>) => {
      return produce(state, draft => {
        draft.toasts.unshift(action.payload);
      })
    },
    [ToastType.DELETE_TOAST]: (state, action: PayloadAction<string, Toast['id']>) => {
      const toastIndex = findIndex(state.toasts, ({ id }) => id === action.payload);

      if (toastIndex === -1) {
        return state;
      }

      return produce(state, draft => {
        draft.toasts.splice(toastIndex, 1)
      })
    },
    [ToastType.EXIT_TOAST]: (state, action: PayloadAction<string, Toast['id']>) => {
      const toastIndex = findIndex(state.toasts, ({ id }) => id === action.payload);

      if (toastIndex === -1) {
        return state;
      }

      return produce(state, draft => {
        draft.toasts[toastIndex].status = ToastStatus.Exit;
      })
    }
  },
  initialState
);

import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import { ToastActions } from 'store/toast';
import { ToastVariant } from 'declaration/Toast';

export function useToast() {
  const dispatch = useDispatch();

  return useMemo(() => {
    return {
      success: (text: string) => dispatch(ToastActions.createToast({ variant: ToastVariant.Success, text })),
      failure: (text: string) => dispatch(ToastActions.createToast({ variant: ToastVariant.Failure, text })),
      warning: (text: string) => dispatch(ToastActions.createToast({ variant: ToastVariant.Warning, text })),
      information: (text: string) => dispatch(ToastActions.createToast({ variant: ToastVariant.Information, text })),
    };
  }, [dispatch]);
}

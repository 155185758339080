import React, { FC, memo, useMemo } from 'react';
import styles from 'components/Toast/Toast.module.scss';
import { Portal } from 'react-portal';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { ToastStatus } from 'declaration/Toast';
import { map, chain } from 'lodash';
import ToastItem from 'components/Toast/ToastItem/ToastItem';

interface Props {}

const Toast: FC<Props> = memo(() => {
  const toasts = useTypedSelector(({ toast: { toasts } }) => toasts);
  const toastLength = useMemo(() => {
    return chain(toasts)
      .filter(({ status }) => status === ToastStatus.Appear)
      .size()
      .value();
  }, [toasts]);

  return (
    <Portal>
      <div className={styles.toast} data-testid="toast">
        {map(toasts, (toast, key) => (
          <ToastItem key={toast.id} toast={toast} option={{ y: toastLength - key - 1 }} />
        ))}
      </div>
    </Portal>
  );
});

export default Toast;

import axios, { AxiosResponse } from 'axios';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createAsyncSagaAction } from 'cores/createAsyncSagaAction';
import { createAsyncSagaReducerMap } from 'cores/createAsyncSagaReducerMap';
import { Ovipa } from 'declaration/Ovipa';
import { action, PayloadAction } from 'typesafe-actions';

export enum AnalyticsType {
  GET_ANALYTICS = '@analytics/GET_ANALYTICS',
  GET_COMPANIES = '@analytics/GET_COMPANIES',
  TRUNCATE_ANALYTICS = '@analytics/TRUNCATE_ANALYTICS',
}

export interface AnalyticsState {
  companies: Array<{ name: string; slug: string }>;
  analytics: Array<Ovipa>;
}

export const AnalyticsActions = {
  truncateAnalytics: () => action(AnalyticsType.TRUNCATE_ANALYTICS),
  getCompanies: createAsyncSagaAction(AnalyticsType.GET_COMPANIES, () => {
    return axios.get(`/admin/analytics/ovipa/companies/`);
  }),
  getAnalytics: createAsyncSagaAction(AnalyticsType.GET_ANALYTICS, (type: string, params: URLSearchParams | object) => {
    return axios.get(`/admin/analytics/${type}/`, { params });
  }),
};

const initialState: AnalyticsState = {
  companies: [],
  analytics: [],
};

export default handleActions<AnalyticsState, any>(
  {
    [AnalyticsType.TRUNCATE_ANALYTICS]: (state) => {
      return produce(state, (draft) => {
        draft.analytics = [];
      });
    },
    ...createAsyncSagaReducerMap(AnalyticsType.GET_COMPANIES, {
      onSuccess: (state, action) => {
        return produce(state, (draft) => {
          draft.companies = action.payload.data;
        });
      },
    }),
    ...createAsyncSagaReducerMap(AnalyticsType.GET_ANALYTICS, {
      onSuccess: (state, action: PayloadAction<string, AxiosResponse<Array<Ovipa>>>) => {
        return produce(state, (draft) => {
          draft.analytics = action.payload.data;
        });
      },
    }),
  },
  initialState
);

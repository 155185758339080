import React, { FC, memo } from 'react';
import styles from './Login.module.scss';
import LoginForm from 'components/LoginForm/LoginForm';

interface Props {}

const Login: FC<Props> = memo(() => {
  return (
    <div className={styles.login}>
      <LoginForm />
    </div>
  );
});

export default Login;

import { get } from 'lodash';
import FastVector from 'fast-vector';

export function getPosition(element: Element) {
  let target: Element | null = element;
  const position = new FastVector(0, 0);

  while (target) {
    if (target.tagName === 'BODY') {
      const scrollX = target.scrollLeft || document.documentElement.scrollLeft;
      const scrollY = target.scrollTop || document.documentElement.scrollTop;

      position.x += get(target, 'offsetLeft') - scrollX + target.clientLeft;
      position.y += get(target, 'offsetTop') - scrollY + target.clientTop;
    } else {
      position.x += get(target, 'offsetLeft') - target.scrollLeft + target.clientLeft;
      position.y += get(target, 'offsetTop') - target.scrollTop + target.clientTop;
    }

    target = get(target, 'offsetParent');
  }

  return position;
}
